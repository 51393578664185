import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import { Button, Menu, Modal, Icon } from "semantic-ui-react";
import ProjectChangesDropdown from "./ProjectChangesDropdown.js";
import moment from "moment";


class ChangeHistory extends React.Component {
	constructor(props) {
		super(props);
		this.state = { open: false, filter: "", data: [] };
		this.debouncer = false;
	}

	componentDidMount() {
        this.setState({ data: this.props.app.ui.getJobDataChanges() });
        console.log("Run job data update");
		this.update();
	}

	componentDidUpdate(prevProps, prevState) {
		this.update();
	}

	open() {
		this.setState({ open: true, data: this.props.app.ui.getJobDataChanges() });
        
	}

	close() {
		this.setState({ open: false, filter: "" });
	}

	changeFilter(filter) {
		this.setState({ filter });
	}

    componentWillUnmount() {
        if (this.debouncer) clearTimeout(this.debouncer);
        this.debouncer = false;
    }

	update() {
		
		if (
			this.props.app.ui.lastJobDataChangesCalculation >=
			this.props.lastChange
		)
			return null;
		if (this.debouncer) clearTimeout(this.debouncer);
        console.log("Run job data update");
		this.debouncer = setTimeout(
			_ =>
				this.setState({ data: this.props.app.ui.getJobDataChanges() }),
			500
		);
	}

	render() {
		const dx = [];

        for(let project of Object.keys(this.state.data)){
            let dates = this.state.data[project];
            let by = "";
            if((dates[0].updatedBy ?? null) !== null){
                by = `      ${this.props.t("changeHistory.by")} ` + (dates[0].updatedBy?.name ?? "");
            }

            dx.push(<div className="collisionHandler" key={project}>
                    <div className="resourceName">
                    {dates[0].bstn}: {dates[0].project} <span className="projectlastmoddate"> - {this.props.t("changeHistory.lastModified")} <i>{moment(dates[0].updated).format("YYYY-MM-DD HH:mm:ss")}</i>&nbsp;{by}</span>
                    <div><ProjectChangesDropdown t={this.props.t} dates={dates}/></div>
                    </div>
                
                </div>);
        }
		
		return (
			<Modal
				centered={false}
				className="collisionModal"
				trigger={
					// <Button
					// 	icon="history"
					// 	onClick={e => this.open()}
						
					// />
                    <div style={calButtonStyle}>
                        <Button
                            icon="history"
                            onClick={e => this.open()}
                            style={{"height": "40px"}}
                        />
                        {Object.keys(this.state.data).length > 0 && (
                            <div
                            style={badgeStyle}
                          >
                            </div>
                        )}
                        
                    </div>
				}
				open={this.state.open}
				onClose={e => this.close()}
			>
				{(
					<React.Fragment>
						<Menu>
							<Menu.Item header>
								{this.props.t("changeHistory.heading")}
							</Menu.Item>
							
							<Menu.Menu position="right">
								<Menu.Item
									name="close it"
									onClick={e => this.close()}
								>
									<Icon name="close" />
								</Menu.Item>
							</Menu.Menu>
						</Menu>
						<Modal.Content>
							<div className="changehistoryWrapper">{dx}</div>
						</Modal.Content>
					</React.Fragment>
				)}
			</Modal>
		);
	}
}
const calButtonStyle = { position: "relative", display: "inline-block" };
const badgeStyle = {
    position: "absolute",
    top: '3px',
    right: '6px',
    backgroundColor: '#2066B6CC',
    color: "white",
    borderRadius: "50%",
    width: "6px",
    height: "6px",
    fontSize: "0.7rem",
    textAlign: "center",
    lineHeight: "14px",
    fontWeight: "bold",
    zIndex: 50,
  };

export default withTranslation()(inject("app")(observer(ChangeHistory)));
