import React from "react";
import { withTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import {
	Button,
	Menu,
	Modal,
	Icon, Form, Dropdown
} from "semantic-ui-react";
import moment from "moment";

class DetailExporter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			time: "HORIZONTAL",
			data: {}
		};
	}

	open() {

				const start = moment(this.props.app.ui.calStart).format("YYYY-MM-DD");
		const end = moment(this.props.app.ui.calEnd).add(1,"day").format("YYYY-MM-DD");

		console.log(this.props.app.ui.calStart, this.props.app.ui.calEnd, start,end);

		let data = {
			resources: [],
			resnames: [],
			days: {},
			start: start,
			end: end
		};


		let rescount = -1;
		let rowcount = 0;
		let counts = {};
		let currentResName = "";

		for (let row of document.querySelectorAll(".xls_row")){
			const resnameE = row.querySelector(".xls_resname");
			if (resnameE){
				rowcount = 0;
				rescount++;
				currentResName = resnameE.textContent;
				data.resnames.push(resnameE.textContent);
				data.resources.push({
					name: resnameE.textContent,
					rows: [[]]
				});
			} else {
				data.resources[rescount].rows.push([]);
				rowcount++;
			}

			for (let box of row.querySelectorAll(".xls_box")){
				//console.log(box.dataset.start, start ,end);
				if (box.dataset.start < start || box.dataset.start > end)
					continue;


				const titleBox = box.querySelector(".xls_title");
				const startBox = box.querySelector(".xls_start");
				const endBox = box.querySelector(".xls_end");
				const contentBox = box.querySelector(".xls_content");
				const size = box.dataset.size ? (parseInt(box.dataset.size)-1) : 0;

				if (!(box.dataset.start in data.days))
					data.days[box.dataset.start] = [[]];

				let boxdata = {
					date: box.dataset.start,
					resource: currentResName,
					title: titleBox ? titleBox.textContent : "",
					start: startBox ? startBox.textContent : "",
					end: endBox ? endBox.textContent : "",
					content: contentBox ? contentBox.textContent.replace(/\n\n/g,"\n").trim() : ""
				};
				data.resources[rescount].rows[rowcount-size].push(boxdata);

				const countkey = box.dataset.start+"-"+rescount;

				let row = 0;
				if (countkey in counts){
					row = counts[countkey];
					counts[countkey]++;
				} else {
					counts[countkey] = 1;
				}

				if (data.days[box.dataset.start].length <= row)
					data.days[box.dataset.start].push([]);

				data.days[box.dataset.start][row].push(boxdata);


			}
		}

		this.setState({ open: true, data });

	}

	close() {
		this.setState({ open: false });
	}

	render() {
		//console.log(this.state.data);
		// action={""+process.env.REACT_APP_BPO_URL+(this.state.time === "VERTIKAL" ? "/ressourcen/InfraDetailExportVertikal.php" : "/ressourcen/InfraDetailExport.php")}
		let currentStateAction = ""+process.env.REACT_APP_BPO_URL;
		if(this.state.time === "VERTIKAL"){
			currentStateAction = '/ressourcen/InfraDetailExportVertikal.php';
		}
		else if(this.state.time === "VERTIKAL_KOMPRIMIERT"){
			currentStateAction = '/ressourcen/InfraDetailExportVertikalKomprimiert.php';
		}
		else if(this.state.time === "HORIZONTAL_KOMPRIMIERT"){
			currentStateAction = '/ressourcen/InfraDetailExportHorizontalKomprimiert.php';
		}
		else{
			currentStateAction = '/ressourcen/InfraDetailExport.php';
		}

		return (
			<Modal
				centered={false}
				className="detailexportModal"
				trigger={
					<Button icon="print" onClick={(e) => this.open()} />
				}
				open={this.state.open}
				onClose={(e) => this.close()}
			>
				<React.Fragment>
					<Menu>
						<Menu.Item header>{this.props.t("exports.heading")}</Menu.Item>

						<Menu.Menu position="right">
							<Menu.Item name="close it" onClick={(e) => this.close()}>
								<Icon name="close" />
							</Menu.Item>
						</Menu.Menu>
					</Menu>
					<Modal.Content>
						<Form.Field>
							<label>Richtung</label><br />
							<Dropdown selection options={[{
								key: "HORIZONTAL",
								value: "HORIZONTAL",
								text: "horizontal"
							},{
								key: "HORIZONTAL_KOMPRIMIERT",
								value: "HORIZONTAL_KOMPRIMIERT",
								text: "horizontal komprimiert"
							},{
								key: "VERTIKAL",
								value: "VERTIKAL",
								text: "vertikal"
							}, {
								key: "VERTIKAL_KOMPRIMIERT",
								value: "VERTIKAL_KOMPRIMIERT",
								text: "vertikal komprimiert"
							}]} value={this.state.time} onChange={(a,{value}) => this.setState({time: value})} />
							</Form.Field>
						<form
							className="hiddenForm"
							method="post"
							action={""+process.env.REACT_APP_BPO_URL+currentStateAction}
							target="_blank"
						>
							
							<input type="hidden" name="duration" value={JSON.stringify(this.state.data)} />
							<input type="hidden" name="time" value={this.state.time} />
							<Button type="submit" positive fluid>
								{this.props.t("exports.get")}
							</Button>
						</form>
					</Modal.Content>
				</React.Fragment>
			</Modal>
		);
	}
}

export default withTranslation()(inject("app")(observer(DetailExporter)));
