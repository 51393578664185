import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { Button, Icon } from 'semantic-ui-react';


const ProjectChangesDropdown = ({t, dates}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <Button icon labelPosition="right" onClick={() => setIsOpen(!isOpen)}>
        <Icon name="arrow down" />
        {t("changeHistory.details")}
        </Button>

      {isOpen && (
        <div className="dropdown-menu">
          { 
                dates.map((job, ind) => {
                    let by = "";
                    if((job.updatedBy ?? null) !== null){
                        by = ` ${t("changeHistory.by")} ` + (job.updatedBy?.name ?? "");
                    }
                    return (
                    <div className="project changehistory" key={job.id}>
                        <div className="timed">
                            { moment(job.start).format("YYYY-MM-DD")}
                        </div>
                        <div className="name">
                            {job.process}
                        </div>
                        <div className="lastmodified">
                            {t("changeHistory.lastModified")} <i>{moment(job.updated).format("YYYY-MM-DD HH:mm:ss")}</i>&nbsp;{by}
                        </div>    
                    </div>
                )})
            }
        </div>
      )}
    </div>
  );
};

export default ProjectChangesDropdown;